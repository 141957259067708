body {
	font-family: Arial, sans-serif;
	background-color: #1e1e1e;
	color: #fff;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.container {
	max-width: 400px;
	width: 100%;
	text-align: center;
	padding: 20px;
	background-color: #333;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
	margin-bottom: 20px;
}

.input-wrapper {
	display: flex;
	margin-bottom: 10px;
}

input[type='text'] {
	flex-grow: 1;
	padding: 8px;
	border: none;
	border-radius: 4px;
	margin-right: 10px;
}

/* Styles for the "Add Todo" button */
.add-todo-button {
	padding: 8px 16px;
	background-color: #2ecc71; /* Green */
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.add-todo-button:hover {
	background-color: #27ae60; /* Slightly darker green on hover */
}

/* Styles for the "Delete Todo" button */
.delete-todo-button {
	padding: 8px 16px;
	background-color: #e74c3c; /* Red */
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.delete-todo-button:hover {
	background-color: #c0392b; /* Slightly darker red on hover */
}

ul {
	list-style: none;
	padding: 0;
}

li {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
	background-color: #444;
	border-radius: 4px;
	margin-bottom: 5px;
}

input[type='checkbox'] {
	margin-right: 10px;
}

.completed span {
	text-decoration: line-through;
	opacity: 0.7;
}
